/* Contact Container */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Center vertically */
  background-color: #f9f9f9; /* Light background */
  padding: 2rem;
}

.contact-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Contact Form Styling */
.contact-form {
  width: 100%;
  max-width: 600px; /* Wider max width than login/register */
  padding: 2rem;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  width: 100%; /* Full width within form */
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Ensure the textarea is larger */
.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

/* Button Styling */
.contact-form button {
  width: 100%; /* Full width */
  padding: 0.75rem;
  font-size: 1.1rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Success Message Styling */
.success-message {
  color: #28a745; /* Green for success */
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
}

/* Footer.css */
.footer {
    background-color: #f8f9fa;
    padding: 1.5rem 2rem;
    text-align: center;
    border-top: 1px solid #ddd;
  }
  
  .footer-content {
    margin-bottom: 1rem;
  }
  
  .footer-content h4 {
    margin: 0 0 0.5rem;
    color: #007BFF;
  }
  
  .footer-content p {
    margin: 0.25rem 0;
    color: #333;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 0.875rem;
    color: #666;
  }
  
/* Home.css */

.home-container {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  z-index: 1;
  padding: 1rem;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.cta-button {
  padding: 0.75rem 1.5rem;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
}

/* About Us Section */
.about-us {
  padding: 2rem;
  background-color: #f8f9fa;
  text-align: center;
}

.about-us h2 {
  color: #007BFF;
  margin-bottom: 1rem;
}

.about-us p {
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Packages Preview Section */
.packages-preview {
  padding: 2rem;
  background-color: #ffffff;
  text-align: center;
}

.packages-preview h2 {
  color: #007BFF;
  margin-bottom: 2rem;
}

.package-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.package-card {
  background-color: #f0f8ff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.package-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.package-card h3 {
  color: #333;
}

.package-card p {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

/* Icon Styling for Packages */
.package-card .package-icon {
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

.silver-icon {
  color: #C0C0C0; /* Silver color */
}

.gold-icon {
  color: #FFD700; /* Gold color */
}

.diamond-icon {
  color: #B9F2FF; /* Diamond color */
}

.learn-more-button {
  padding: 0.5rem 1rem;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.learn-more-button:hover {
  background-color: #0056b3;
}

/* Contact and Newsletter Section */
.contact-newsletter {
  padding: 2rem;
  background-color: #f8f9fa;
  text-align: center;
}

.contact-newsletter h2 {
  color: #007BFF;
  margin-bottom: 1rem;
}

.contact-newsletter p {
  margin-bottom: 1rem;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.newsletter-form input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  width: 300px;
}

.newsletter-form button {
  padding: 0.75rem 1rem;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.newsletter-form button:hover {
  background-color: #0056b3;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .package-cards {
    flex-direction: column;
    gap: 1.5rem;
  }

  .package-card {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .newsletter-form input {
    width: 80%;
  }
}

/* Navbar.css */
.navbar-container {
    position: sticky;
    top: 0; /* This ensures it sticks to the top */
    width: 100%;
    background-color: #ffffff;
    z-index: 1000; /* Ensures the navbar stays above other elements */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #f8f9fa;

  }
  
  .logo a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #007BFF;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    display: inline;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s;
  }
  
  .navbar-links a:hover {
    color: #007BFF;
  }
  
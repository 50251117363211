.package-details {
    font-family: 'Arial', sans-serif;
    padding: 2rem;
    text-align: center;
  }
  
  .back-button {
    display: inline-block;
    margin-bottom: 1rem;
    color: #007BFF;
    text-decoration: none;
  }
  
  .package-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .package-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .features {
    margin-top: 2rem;
  }
  
  .features h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .features ul {
    list-style: none;
    padding: 0;
  }
  
  .features li {
    font-size: 1.1rem;
    margin: 0.5rem 0;
  }
  
  .pricing {
    margin-top: 2rem;
  }
  
  .pricing h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .cta-button {
    padding: 0.75rem 1.5rem;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1.5rem;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  /* Accent Colors for Each Package */
  .silver-package .package-icon { color: #C0C0C0; } /* Silver */
  .gold-package .package-icon { color: #FFD700; } /* Gold */
  .diamond-package .package-icon { color: #00BFFF; } /* Diamond */
  
/* Packages.css */
.packages-page {
  padding: 2rem;
  background-color: #f8f9fa;
  text-align: center;
}

.packages-page h2 {
  color: #007BFF;
  margin-bottom: 2rem;
}

.package-detail-card {
  background-color: #ffffff;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 600px;
  margin: 1.5rem auto;
}

.package-detail-card h3 {
  color: #333;
  margin-top: 0;
}

.package-detail-card p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}
.package-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.silver-icon {
  color: #C0C0C0;
}

.gold-icon {
  color: #FFD700;
}

.diamond-icon {
  color: #B9F2FF;
}

/* index.css */

/* Reset styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Basic global styles */
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f4f4f9;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #0056b3;
}

button {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:focus {
  outline: none;
}
